<template>
  <div>
    <div v-if="!loading">
      <div v-if="week">
        <!-- <h4 style="text-align:center;">{{week.name}}</h4> -->
        <b-form-select class="selector" v-model="newweek" :options="weeks" size="sm"></b-form-select>
        <div v-for="challenge in week.challenges" :key="challenge.id" style="margin-top: 10px;">
            <Faceoff :challenge="challenge" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center">
        <b-spinner variant="danger" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";
import Faceoff from "@/components/NewFaceoff";

export default {
  name: "Challenges",
  components: {
    Faceoff,
  },
  data() {
    return {
      week: null,
      newweek: null,
      weeks: [{ value: null, text: "Select" }],
      loading: true,
    };
  },
  watch: {
    newweek() {
      this.loading = true;
      let self = this;
      api(
        `query { week(wid:${this.newweek}){ id name challenges { id spread totalpoints freePlay line { id name } lineFOdds lineAOdds spread spreadFOdds spreadAOdds totalpoints totalFOdds totalAOdds freePlay date home { id name logo } away { id name logo } results { winner { id name } spread { id name } homePoints awayPoints totalpoints }  bets { id } } } }`
      ).then((data) => {
        self.week = data.week;
        self.loading = false;
      });
    },
  },
  beforeMount() {
    let self = this;
    api(
      `query { 
        currentWeek { 
          id
          name
          challenges { 
            id
            live
            line { 
              id 
              name
            }
            lineFOdds
            lineAOdds
            spread
            spreadFOdds
            spreadAOdds
            totalpoints
            totalFOdds
            totalAOdds
            freePlay
            date
            home{
              id
              name
              abbrev
              logo
            } 
            away {
              id
              name
              abbrev
              logo
            }
            results { 
              winner {
                id
                name
              }
              spread {
                id
                name
              }
              homePoints
              awayPoints
              totalpoints 
            }
            bets {
              id
            } 
          }
        }
        playableWeek {
          id
          name
        }
      }`
    ).then((data) => {
      self.week = data.currentWeek;
      self.weeks[0].text = data.currentWeek.name

      data.playableWeek.forEach((element) => {
        self.weeks.push({
          value: element.id,
          text: element.name,
        });
      });
      self.loading = false;
    });
  },
  methods: {
    viewChallenge(cid) {
      this.$router.push({
        name: "Challenge",
        params: { id: cid },
      });
    },
  },
};
</script>

<style scoped>
.selector {
  background-color: #222;
  border-color: #303030;
  color: white;
  font-size: larger;
}
</style>